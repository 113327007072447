<script>
import { required, requiredIf, email, minLength, maxLength, sameAs } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ApiManage',
  directives: { mask },
  components: {
    Tabs: () => import('@/components/general/Tabs'),
    Modal: () => import('@/components/general/Modal'),
    Action: () => import('@/components/general/Action'),
    InputField: () => import('@/components/general/InputField'),
    FormSection: () => import('@/components/general/FormSection')
  },

  data () {
    return {
      selectedProfile: null,
      editPassword: false,
      formData: {
        profile: null,
        email: null,
        id: null,
        name: null,
        username: null,
        password: null,
        confirmPassword: null
      },

      profileOptions: [],
      tabLinks: [
        {
          text: 'community.index:modal.tab.link.personal.data'
        }
      ],

      tabLinkActive: 0
    }
  },

  validations: {
    formData: {
      name: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },

      username: {
        required
      },

      email: {
        required,
        email
      },

      password: {
        required: requiredIf(function () {
          return !this.isEditing
        }),

        regexPassword: (event) => {
          const regexPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})')

          return regexPassword.test(event)
        }
      },

      confirmPassword: {
        required: requiredIf(function () {
          return !this.isEditing
        }),

        sameAs: sameAs('password')
      }
    }
  },

  computed: {
    ...mapState([ 'fetching' ]),
    isEditing () {
      return this.$route.params.id
    }
  },

  created () {
    this.setFetching(true)

    if (this.isEditing) {
      const userId = this.$route.params.id

      this.attemptGetUsersList({
        query: {},
        filter: { user_id: userId },
        page: 1
      })
        .then(() => {
          this.attemptManageUser(userId)
        })
        .finally(() => {
          this.formData = {
            ...this.formData,
            ...this.deepClone(this.$store.getters.getManagingUser)
          }
        })
    }

    this.attemptListProfiles({
      limit: 100000,
      filter: { active: true }
    })
      .then(({ data }) => {
        data.forEach(profile => {
          this.profileOptions.push({
            label: profile.name,
            value: profile.id
          })
        })
      })
      .finally(() => {
        this.setFetching(false)
      })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptCreateUserApi',
      'attemptUpdateUserApi',
      'attemptListProfiles',
      'attemptGetUsersList',
      'attemptManageUser'
    ]),

    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.setFetching(true)

      if (this.isEditing) {
        this.updateUserApi()
      } else {
        this.createUserApi()
      }
    },

    createUserApi () {
      this.attemptCreateUserApi(this.formData)
        .then(() => {
          this.setFeedback({ message: this.$t('community.users:register.success') })
          this.leave()
        })
        .catch(({ data }) => {
          let message = this.$t('global.error')

          if (data && data.error.message === 'admin_already_registered') {
            message = this.$t('community.admin:register.already.registered')
          } else if (data.error.message === 'email_already_registered') {
            message = this.$t('global:form.validation.email')
          } else if (data.error.validation.password === 'invalid_password_string_required') {
            message = this.$t('global:form.validation.password')
          } else if (data.error.message === 'validation_error') {
            if (data.error.validation && data.error.validation.email) {
              message = this.$t('global:form.validation.email.format')
            }
          }

          this.setFeedback({ message: message })
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    updateUserApi () {
      this.attemptUpdateUserApi(this.formData)
        .then(() => {
          this.setFeedback({ message: this.$t('community.users:update.success') })
          this.leave()
        })
        .catch((data) => {
          if (data.data.error.validation.password === 'invalid_password_string_required') {
            this.setFeedback({ message: this.$t('global:form.validation.password') })
          } else {
            this.setFeedback({ message: this.$t('global.error') })
          }
        })
        .finally(() => {
          this.setFetching(false)
        })
    },

    leave () {
      this.$router.push({ name: 'users.index' })
    },

    changeTab (index) {
      this.tabLinkActive = index
    },

    togglePasswordEdit () {
      this.editPassword = true
    }
  }

}
</script>

<template>
  <Modal
    :active="!fetching"
    prevent-e-s-c
    @close="leave()"
  >
    <div class="modal-form modal-add-user">
      <span class="modal-subtitle">{{ $t('community.api:modal.subtitle') }}</span>
      <h2 class="modal-title">
        {{ isEditing ? $t('community.api.index:modal.title.editing') :$t('community.api.index:modal.title.add') }}
      </h2>
      <Tabs
        slot="tabs"
        :links="tabLinks"
        :index-active="tabLinkActive"
        dark
        @tabChange="changeTab($event)"
      />
      <form @submit.prevent="submit()">
        <InputField
          v-model="formData.username"
          :label="$t('global:form.username')"
          :validation="$v.formData.username"
          :readonly="!!isEditing"
          dark
        />
        <InputField
          v-model="formData.name"
          :label="$t('global:form.full.name')"
          autocomplete="name"
          :validation="$v.formData.name"
          dark
        />
        <InputField
          v-model="formData.email"
          :label="$t('global:form.email')"
          autocomplete="email"
          :validation="$v.formData.email"
          dark
        />
        <FormSection :title="$t('users.manage:access.password')">
          <template v-if="isEditing && !editPassword">
            <Action
              :text="$t('users.manage:access.password.button')"
              type="button"
              primary
              dark
              fixed-width
              @click="togglePasswordEdit()"
            />
          </template>
          <template v-else>
            <InputField
              v-model="formData.password"
              :label="$t('global:form.password')"
              type="password"
              autocomplete="password"
              :validation="$v.formData.password"
              dark
            />
            <InputField
              v-model="formData.confirmPassword"
              :label="$t('global:form.confirm.password')"
              type="password"
              autocomplete="password"
              :validation="$v.formData.confirmPassword"
              dark
            />
          </template>
        </FormSection>
        <div class="form-submit text-center">
          <Action
            :text="isEditing ? $t('global:save') : $t('global:register')"
            type="button"
            secondary
            large
            submit
            fixed-width
          />
        </div>
      </form>
    </div>
  </Modal>
</template>

<style>
  .profiles-selection {
    margin-top: 60px;
  }
</style>
